import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { FaHeart } from 'react-icons/fa6';
import { FaCartShopping } from 'react-icons/fa6';
import logo from '../assets/footer_logo.png';
import { subscribe } from '../store/reducers/homeReducer';
const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { card_product_count, wishlist_count } = useSelector((state) => state.card);

  const [email, setEmail] = useState('');
  const { successMessage, errorMessage } = useSelector((state) => state.home);

  const handleSubscribe = () => {
    const emailRegex = /^\S+@\S+\.\S+$/; 
    if (!email || !emailRegex.test(email)) {
      return;
    }
    dispatch(subscribe({email}));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);


  return (
    <footer className="bg-[#f3f6fa]">
      <div className="w-[85%] flex flex-wrap mx-auto border-b py-16 md-lg:pb-10 sm:pb-6">
        <div className="w-3/12 lg:w-4/12 sm:w-full">
          <div className="flex flex-col gap-3">
            <img className="w-[220px] h-[120px]" src={logo} alt="logo" />
            <ul className="flex flex-col gap-2 text-slate-600">
              <li>Phone : +1 (720) 422-1156 (9am to 6pm Mountain Time, Mon-Sat) </li>
              <li>Email : support@diyflexrealty.com</li>
            </ul>
          </div>
        </div>

        <div className="w-5/12 lg:w-8/12 sm:w-full">
          <div className="flex justify-center sm:justify-start sm:mt-6 w-full">
            <div>
              <h2 className="font-bold text-lg mb-2">Useful Links</h2>
              <div className="flex justify-between gap-[80px] lg:gap-[40px]">
                <ul className="flex flex-col gap-2 text-slate-600 text-sm font-semibold">
                  <li><Link>About Us</Link></li>
                  <li><Link>About Our Shop</Link></li>
                  <li><Link>Privacy Policy</Link></li>
                </ul>
                <ul className="flex flex-col gap-2 text-slate-600 text-sm font-semibold">
                  <li><Link>Our Service</Link></li>
                  <li><Link>Company Profile</Link></li>
                  <li><Link>Privacy Policy</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-4/12 lg:w-full lg:mt-6">
          <div className="w-full flex flex-col justify-start gap-5">
            <h2 className="font-bold text-lg mb-2">
              Stay informed with exclusive updates on the latest property listings!
            </h2>
            <span>Please share your email</span>
            <div className="h-[50px] w-full bg-white border relative">
              <input
                className="h-full bg-transparent w-full px-3 outline-0"
                type="text"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="h-full absolute right-0 bg-[#37AFE1] text-white uppercase px-4 font-bold text-sm"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </div>
            <ul className="flex justify-start items-center gap-3">
              <li>
                <a
                  className="w-[38px] h-[38px] hover:bg-[#37AFE1] hover:text-white flex justify-center items-center bg-white rounded-full"
                  href="https://www.facebook.com/people/DIY-Flex-Realty/61566891073607"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  className="w-[38px] h-[38px] hover:bg-[#37AFE1] hover:text-white flex justify-center items-center bg-white rounded-full"
                  href="#"
                >
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  className="w-[38px] h-[38px] hover:bg-[#37AFE1] hover:text-white flex justify-center items-center bg-white rounded-full"
                  href="https://www.instagram.com/diyflexrealty"
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-[90%] flex flex-wrap justify-center items-center text-slate-600 mx-auto py-5 text-center">
        <span>Copiright @ 2024 All Rights Reserved </span>
      </div>

      <div className="hidden fixed md-lg:block w-[50px] h-[110px] bottom-3 right-2 bg-white rounded-full p-2">
        <div className="w-full h-full flex gap-3 flex-col justify-center items-center">
          <div
            onClick={() => navigate(userInfo ? '/card' : '/login')}
            className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]"
          >
            <span className="text-xl text-green-500">
              <FaCartShopping />
            </span>
            {card_product_count !== 0 && (
              <div className="w-[20px] h-[20px] absolute bg-red-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                {card_product_count}
              </div>
            )}
          </div>
          <div
            onClick={() =>
              navigate(userInfo ? '/dashboard/my-wishlist' : '/login')
            }
            className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]"
          >
            <span className="text-xl text-green-500">
              <FaHeart />
            </span>
            {wishlist_count !== 0 && (
              <div className="w-[20px] h-[20px] absolute bg-red-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                {wishlist_count}
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
