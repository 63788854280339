import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customer_login, messageClear } from '../store/reducers/authReducer';
import toast from 'react-hot-toast';
import { FadeLoader } from 'react-spinners';
import './Login.css';
import api from '../api/api';

const Login = () => {
  const navigate = useNavigate();
  const { loader, errorMessage, successMessage, userInfo } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const login = (e) => {
    e.preventDefault();
    dispatch(customer_login(state));
  };

  const handleForgotPassword = async () => {
    try {
      const response = await api.post('/customer/reset-password', {
        email: state.email, // Sending the email in the body
      });
      toast.success(`Password reset email sent to ${state.email}.`);
    } catch (error) {
      toast.error('Failed to send reset email. Please try again.');
    }
  };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (userInfo) {
      navigate('/');
    }
  }, [successMessage, errorMessage]);

  return (
    <div>
      {loader && (
        <div className="w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]">
          <FadeLoader />
        </div>
      )}
      <Header />
      <div className="login-container">
        <div className="login-form-container">
          <div className="form-section">
            <h2 className="form-title">Login</h2>
            <form onSubmit={login} className="form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  onChange={inputHandle}
                  value={state.email}
                  className="form-input"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  onChange={inputHandle}
                  value={state.password}
                  className="form-input"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  required
                />
              </div>
              <button className="submit-button">Login</button>
            </form>
            <div className="text-center text-slate-600 pt-1">
              <p>
                Don't Have An Account?{' '}
                <Link className="text-blue-500" to="/register">
                  Register
                </Link>
              </p>
              {/* Add Forgot Password link */}
              <p>
                <button
                  onClick={handleForgotPassword}
                  className="text-blue-500"
                  to="/reset-password"
                >
                  Forgot Password?
                </button>
              </p>
            </div>
            <div className="separator">
              <div className="separator-line"></div>
              <span className="separator-text">Or</span>
              <div className="separator-line"></div>
            </div>
            <a target="_blank" href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}>
              <div className="external-link">Login As a Seller</div>
            </a>
            <a target="_blank" href={`${process.env.REACT_APP_DASHBOARD_URL}/register`}>
              <div className="external-link seller-login">
                Register As a Seller
              </div>
            </a>
          </div>
          <div className="image-section">
            <img
              className="image"
              src="https://static.wixstatic.com/media/1bb613_555f8898f1d9463cbc354fc5c6c93f13f000.jpg/v1/fill/w_320,h_542,al_c,q_80,usm_0.33_1.00_0.00,enc_auto/1bb613_555f8898f1d9463cbc354fc5c6c93f13f000.jpg"
              alt="Login"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
