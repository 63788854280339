import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LocationSearchBar.css';

const LocationSearchBar = () => {
  const [address, setAddress] = useState('');
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!address.trim()) {
      alert('Please enter your home address.');
      return;
    }
    navigate('/lead_form');
  };

  return (
    <div className="container">
      <h3 className="searchTitle">
        Colorado’s Simple, Speedy Home Sale Solution!
      </h3>
      <div className="searchInputContainer">
        <input
          type="text"
          placeholder="Enter your home address"
          className="searchInput"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <button
          onClick={handleButtonClick}
          className="listHomeButton"
          disabled={!address.trim()} 
        >
          GET MY OFFER
        </button>
      </div>
      <p className="listHomeInfo">
        <i className="fa fa-phone"></i>  Get a free consultation call here or contact a specialist at +1 (720) 422-1156 (9am to 6pm Mountain Time, Mon-Sat)
      </p>
    </div>
  );
};

export default LocationSearchBar;
